import { createSlice } from "@reduxjs/toolkit";
import { collection, query, onSnapshot, limit, orderBy, doc, getDoc } from 'firebase/firestore';
import { dispatch } from "redux/store";
import { DB } from 'utils/firebase';
import { OrderState } from "types/order";

const initialState: OrderState = {
  isLoading: false,
  error: null,
  orders: [],
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      // state.orders = [];
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOrders() {
  return () => {
    dispatch(slice.actions.startLoading());

    let orders: any[] = [];
    const ref = collection(DB, 'orders',);
    const filteredRef = query(ref, orderBy('createdAt', 'desc'), limit(100));

    return onSnapshot(filteredRef, (snapshot) => {
      snapshot.docChanges().forEach(change => {
        const { type, doc } = change;
        if (type === 'added') {
          orders.push({
            ...doc.data(),
            id: doc.id,
          });
        }
        if(type === 'modified') {
          let data = doc.data();
          const index = orders.findIndex(item => item.id == data.id);

          orders = [
            ...orders.slice(0, index),
            data,
            ...orders.slice(index+1)
          ]
        }
        if (type === 'removed') {
          const data = doc.data();
          const index = orders.findIndex(item => item.id == data.id);
          orders = [
            ...orders.slice(0, index),
            ...orders.slice(index+1)
          ]
        }
      });

      dispatch(slice.actions.getOrdersSuccess(orders));
    }, (error) => {
      dispatch(slice.actions.hasError(error.message));
    });
  }
}
