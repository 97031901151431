// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
// icons
import GroupIcon from '@mui/icons-material/Group';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import MapIcon from '@mui/icons-material/Map';
import KeyIcon from '@mui/icons-material/Key';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  shipper: <TwoWheelerIcon />,
  map: <MapIcon />,
  role: <KeyIcon />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Orders', path: PATH_DASHBOARD.general.orders, icon: ICONS.booking },
      { title: 'Roles', path: PATH_DASHBOARD.general.roles, icon: ICONS.role },
      { title: 'Users', path: PATH_DASHBOARD.general.users, icon: ICONS.user },
      { title: 'Shippers', path: PATH_DASHBOARD.general.shippers, icon: ICONS.shipper },
      { title: 'Map', path: PATH_DASHBOARD.general.map, icon: ICONS.map },
    ],
  },
];

export default navConfig;
