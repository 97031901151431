import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
// import Option2Layout from '../layouts/Option2';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: '/dashboard',
      element: <AuthGuard>
        <DashboardLayout />
      </AuthGuard>,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'orders', element: <OrdersPage /> },
        { path: 'orders/:orderID', element: <OrderDetailPage /> },
        { path: 'users', element: <GeneralApp /> },
        { path: 'shippers', element: <ShippersPage /> },
        { path: 'map', element: <MapPage /> },
      ],
    },

    // Auth Routes
    {
      path: '/',
      children: [
        {
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
          index: true
        },
      ],
    },

    // Public Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '*', element: <Navigate to="/404" replace />, index: true },
        { path: '404', element: <NotFound /> },
      ]
    },
  ]);
}

// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/GeneralApp')));
const OrdersPage = Loadable(lazy(() => import('../pages/Orders')));
const OrderDetailPage = Loadable(lazy(() => import('../pages/OrderDetail')));
const ShippersPage = Loadable(lazy(() => import('../pages/Shippers')));
const MapPage = Loadable(lazy(() => import('../pages/Map')));
// Main
// const HomePage = Loadable(lazy(() => import('../pages/Home')));
const LoginPage = Loadable(lazy(() => import('../pages/Login')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));