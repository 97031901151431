import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from 'firebase/firestore';
import { dispatch } from "redux/store";
import { DB } from 'utils/firebase';
import { OrderDetailState } from "types/orderDetail";

const initialState: OrderDetailState = {
  isLoading: true,
  error: null,
  order: null,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      // state.orders = [];
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDER
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOrder(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const orderRef = doc(DB, 'orders', id);
      const docSnap = await getDoc(orderRef);

      if (docSnap.exists()) {
        dispatch(slice.actions.getOrderSuccess(docSnap.data()));
      } else {
        throw new Error('Order not found');
      }
    } catch (error) {
      // console.error(error);
      dispatch(slice.actions.hasError(error.message));
    }
  };
}