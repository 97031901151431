import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import orderReducer from './slices/order';
import orderDetailReducer from './slices/orderDetail';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  order: orderReducer,
  orderDetail: orderDetailReducer,
});

export { rootPersistConfig, rootReducer };
